import React from 'react'
import { graphql } from 'gatsby'

import { Layout } from '../components/layout/Layout'
import { SEO } from '../components/seo'
import { HR } from '../components/styled/HR'
import { postExcerpt } from '../utils/postExcerpt'
import { Bio } from '../components/layout/Bio'

function AboutPageTemplate({
  data: {
    config: { author },
  },
}) {
  return (
    <Layout>
      <SEO
        title={'About ' + author.name}
        description={postExcerpt(author.shortBio, author.bio, 160)}
        image={`/og-images/author/${author.slug.current}.png`}
      />
      <Bio author={author} isSingle />
      <HR />
    </Layout>
  )
}

export default AboutPageTemplate

export const pageQuery = graphql`
  query {
    config: sanityConfig(_id: { eq: "global-config" }) {
      author {
        ...AuthorBio
      }
    }
  }
`
